
import {IdType}     from '_utils/TypeHelper';
import EventManager from '@brainscape/event-manager';
import PropTypes    from 'prop-types';
import React        from 'react';

import {toClassStr} from '_utils/UiHelper';

const RegistrationButton = (props) => {

  const handleClick = (e) => {
    console.log('in RegistrationButton.handleClick');
    EventManager.emitEvent('reg:register:open', {
      categoryId: props.categoryId,
      packId:     props.packId,
      returnTo:   props.returnTo,
      source:     props.source,
      subject:    props.subject,
    });

    if (props.afterClick) { props.afterClick(); }
  }

  const classes = toClassStr(['registration-button', props.className, props.addClasses]);

  return (
    <div className={classes} onClick={handleClick}>
      {props.text || 'Get Started'}
    </div>
  );
}

RegistrationButton.propTypes = {
  afterClick: PropTypes.func,
  categoryId: IdType,
  className:  PropTypes.string,
  packId:     IdType,
  returnTo:   PropTypes.string,
  source:     PropTypes.string,
  subject:    PropTypes.string,
  text:       PropTypes.string,
};

export default RegistrationButton;
