
import mainShared     from '_core/main-shared';
import navbar         from '../public-pages/shared/navbar';

const mainPublic = {

  init: function() {
    mainShared.init();
    navbar.init();

    const cmsRegistrationButtons = document.querySelectorAll('.cms-registration-button');

    if (cmsRegistrationButtons) {
      cmsRegistrationButtons.forEach((button) => {
        button.addEventListener('click', (e) => {
          const source = e.target.getAttribute('data-source') || null;
          this.handleCmsRegistrationButtonClick(e, source);
        });
      });
    }
  },

  handleCmsRegistrationButtonClick(e, source=null) {
    e.stopPropagation();

    const opts = (source) ? {source: source} : null;

    if (window.openRegisterModal) {
      window.openRegisterModal(opts);
    }
  }
};

export default mainPublic;
