
import mainApp        from '_core/main-app';
import mainPublic     from '../../core/main-public';
import profilePage    from '../pages/profile/profile-page';

if (document.readyState === 'loading') {
  // The DOM is still loading, set up the event listener
  document.addEventListener("DOMContentLoaded", () => {
    mainApp.init();
    mainPublic.init();
    profilePage.init();
  });
} else {
  // The DOM has already loaded, initialize immediately
  mainApp.init();
  mainPublic.init();
  profilePage.init();
}
