
import EventManager from '@brainscape/event-manager';
import PropTypes    from 'prop-types';
import React        from 'react';

import {toClassStr} from '_utils/UiHelper';

const LoginButton = (props) => {
  
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    EventManager.emitEvent('reg:login:open', {
      source:  props.source,
      subject: props.subject,
    });
  };

  const classes = toClassStr(['login-button', props.className, props.addClasses]);

  return (
    <div className={classes} onClick={handleClick}>Log In</div>
  );
}

LoginButton.propTypes = {
  source:  PropTypes.string,
  subject: PropTypes.string,
};

export default LoginButton;
